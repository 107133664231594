import React, { useEffect } from 'react'
// import { useEffect } from "react";
import loadable from '@loadable/component'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { TrimStr } from '../components/Trim'
import { Link, graphql } from 'gatsby'
//import Img from "gatsby-image"

const PortfolioRoll = loadable(() => import('../components/PortfolioRoll'));
// const Typewriter = loadable(() => import('typewriter-effect'));
const BriefCall = loadable(() => import('../components/BriefCall'));
// const FormMsg = loadable(() => import('../components/FormMsg'));


const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/portfolio/${props.url}`} className='btnblog'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='btnblogdisabled'>
        {props.text}
      </span>
    )
  }
}



const PortfolioPage = (props) => {
  const { pageContext: { first, group, index, last } } = props
  const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()+'/'
  const nextUrl = (index + 1).toString()+'/'
  //const { data } = props
  let cats = []

  props.data.allMarkdownRemark.edges.forEach(edge => {
    if(!cats.includes(edge.node.frontmatter.industry)){
    cats.push(edge.node.frontmatter.industry)
    }
  })

  useEffect(() => {
        document.getElementById('lang-switch').href = 'https://e-multicontent.pl/portfolio/'
   });

  /*useEffect(() => {

        var lazyBackgrounds = [].slice.call(
          document.querySelectorAll(".lazy-background")
        );

        if (
          "IntersectionObserver" in window &&
          "IntersectionObserverEntry" in window &&
          "intersectionRatio" in window.IntersectionObserverEntry.prototype
        ) {
          let lazyBackgroundObserver = new IntersectionObserver(function (
            entries,
            observer
          ) {
            entries.forEach(function (entry) {
              if (entry.isIntersecting) {
                entry.target.classList.add("visible");
                lazyBackgroundObserver.unobserve(entry.target);

              }
            });
          });
          lazyBackgrounds.forEach(function (lazyBackground) {
            lazyBackgroundObserver.observe(lazyBackground);
          });
        }
      ;

  }, []);*/

    return (
      <>
      <Helmet titleTemplate="%s">



        <meta name="description" content={'See who we have worked with. We invite you to familiarize yourself with our projects! ✔️'}/>
        <meta name='image' content={'https://e-multicontent.com/img/meta/portfolio-meta.jpg'} />
        <meta name='keywords' content={'copywriting, copywriters, marketing, copywriting agency, Wrocław, team of copywriters, work, business, content, content, translations, SEO, blog, texts, articles, graphics, video, websites, online stores, marketplace, e-commerce'} />

        <meta property="og:description" content={'See who we have worked with. We invite you to familiarize yourself with our projects! ✔️'}/>
        <meta property="og:title" content={'Feel free to take a look at our projects | e-multicontent for e-commerce'} />
        <meta property="og:image" content={'https://e-multicontent.com/img/meta/portfolio-meta.jpg'} />
        <meta property="og:url" content={'https://e-multicontent.com/portfolio/'} />
        <link rel="alternate" href="https://e-multicontent.pl/portfolio/" hrefLang="pl-pl" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="e-multicontent.com" />
        <meta property="twitter:url" content="https://e-multicontent.com/portfolio/" />
        <meta name="twitter:title" content="Feel free to take a look at our projects | e-multicontent for e-commerce" />
        <meta name="twitter:description" content="See who we have worked with. We invite you to familiarize yourself with our projects! ✔️" />
        <meta name="twitter:image" content="https://e-multicontent.com/img/apple-touch-icon.png" />

        <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://e-multicontent.com/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Portfolio",
            "item": "https://e-multicontent.com/portfolio/"
          }]
        })}
        </script>
      </Helmet>

      {index === 1 ?
      <Helmet>
      <title>Take a look at our projects | Copywriting | Portfolio | e-multicontent</title>
      <link rel="canonical" href="https://e-multicontent.com/portfolio/"  />
      <meta name="description" content={'Take a look at our projects | Copywriting | Portfolio | e-multicontent! ✔️'}/>
      </Helmet>
      :
      <Helmet>
      <title>{'Page ' + index + ' - Take a look at our projects | Copywriting | Portfolio | e-multicontent'}</title>
      <meta name="robots" content="noindex, nofollow" />
      <meta name="description" content={'Take a look at our projects | Copywriting | Portfolio | e-multicontent! ✔️ Page ' + index}/>
      <link rel="canonical" href={'https://e-multicontent.com/portfolio/'+index+'/'}  />
      </Helmet>
      }



      <Layout>

      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>Portfolio</b></span>
      </nav>
      <div id="portfolio-desktop-nav" className="rows ptf-nav" style={{
        color: 'rgb(23,19,43)',
        lineHeight: '1',
        padding: '10px',
        borderRadius: '20px',
        backgroundColor: 'rgba(255,255,255,0.9)',
        position: 'fixed',
        right: '0%',
        overflow:'scroll',
        maxHeight: '335px',
        top: '55px',
        zIndex: '2',
        textAlign: 'right',
        width:'min-content',
      }}>
      <p style={{fontSize:'10px'}} align="center"><u> Categories:</u></p><br></br>
      {cats.sort().reverse().map(cat => {
        return (
          <>
          <Link key={TrimStr(cat)} className='pnav btn' style={{marginBottom:'2px',width:'100%',fontSize:'10px'}} to={'/portfolio/'+TrimStr(cat)+'/'} >
            {cat}
          </Link>
          <br />
          </>
        )
      })}
      </div>

      <div
      id="portfolio"
        className="full-width-image-container"
        style={{
          backgroundPosition: `bottom right`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: 'contain',
          height:'550px',
          marginTop: '100px',
        }}
      >
        </div>

        <h1
          className="has-text-weight-bold is-size-1"
          style={{
            color: '',
            lineHeight: '1',
            padding: '0.35em',
            //boxShadow: '5px 5px 20px rgb(23,19,43)',
            marginTop: '0%',
            display: 'flex',
            flexWrap:'wrap',
            position: 'absolute',
            left: '7%',
            top: '250px',
            zIndex:'1000',
            textAlign: 'left',
            backgroundColor:'rgba(255,255,255,0.75)'
          }}
        >
        Our&nbsp; <b style={{color:'#36b3d2'}}> portfolio</b>
        <br></br>
        </h1>
        <br></br>
        <h2
        className="mright"
        style={{
          color: 'rgb(23,19,43)',
          lineHeight: '1',
          padding: '0.35em',
          borderRadius: '20px',
          //boxShadow: '5px 5px 20px rgb(23,19,43)',
          marginTop: '0%',
          display: 'block',
          position: 'absolute',
          left: '8%',
          top: '400px',
          backgroundColor:'rgba(255,255,255,0.75)',
          textAlign: 'left',}} > E-multicontent for e-commerce<br></br><br></br> Feel free to take a look at our projects.

          </h2>
          <div className="container section">
          <p align="center">
        <p className="ux" align="center" ><a className="btnoutline" href="#realizacje">⇩</a></p>
          </p>
          <h2 className="title" style={{marginTop:'25px',borderBottom:'5px solid #36b3d2',width:'fit-content'}}>Latest projects:</h2>
          <p>Check out the latest case studies. Learn the details of our work!</p>
          <br />
          <div id="cats" style={{textAlign:'center',width:'90%',margin:'0 5%'}}>
            <p style={{fontSize:'12px'}} align="center"><u> Categories:</u></p><br></br>
          {cats.sort().reverse().map(cat => {
            return (
              <>
              <Link key={TrimStr(cat)} className='pnav btn' style={{margin:'5px',fontSize:'12px'}} to={'/portfolio/'+TrimStr(cat)+'/'} >
                {cat}
              </Link>
              </>
            )
          })}
          </div>
            <div id="realizacje" style={{marginTop: '30px'}} className="content">
              <PortfolioRoll posts={group} />
            </div>
            <br />
            <br />
            <div className='buttons is-centered' style={{marginTop:'25px'}}>
              <PaginationLink test={first} url={previousUrl} text='<' />
              <PaginationLink test={last} url={nextUrl} text='>' />
            </div>
            <br></br>
            <br></br>
          </div>

          <BriefCall />
            {/*kontakt

            <div style={{backgroundColor:'rgb(245, 245, 245)',padding:'5%'}}>


            <p className="is-size-2" style={{fontWeight:'800'}}>
            <Typewriter
                onInit={(typewriter) => {
                  typewriter.typeString("<span style='color:#36b3d2'>Skontaktuj się</span> z nami:")
                    .pauseFor(2500)
                    .deleteAll()
                    .start();
                }}
                options={{
                  autoStart: true,
                  loop: true,
                }}
              />
            </p>
            <br />
            <br />


            <div className="columns" style={{margin:'auto'}}>

            <div className="column">
            <h3 style={{fontSize:'1em',textAlign:'center'}}>Chcesz się z nami skontaktować?<br /> Wypełnij formularz poniżej, możesz również załączyć plik.</h3>
            <br />
            <FormMsg/>
            </div>

            <div className="column" style={{position:'relative',textAlign:'center'}}>
            <p>Chcesz wysłać maila?</p><br />
            <p><a href="mailto:info@e-multicontent.com" rel="noopener noreferrer" className="btn"  style={{marginTop:'-30px',marginBottom:'30px'}} target="_blank">info@e-multicontent.com</a></p>


            <p>Czy może wolisz porozmawiać?</p><br />
            <p><a href="tel:+48713074517" rel="noopener noreferrer" style={{marginTop:'-30px'}}  className="btn" target="_blank">+48 71 307 45 17</a></p>


            <Img fluid={data.file.childImageSharp.fluid}
            alt="Contact us - e-multicontent - copywriting, tłumaczenia, seo, strony internetowe, e-commerce, marketplace"
            style={{
              padding: "0",
              borderRadius: "10px",width:'100%',minHeight:'244px',maxHeight:'100%',
              maxWidth:'550px', border:'3px solid #f5f5f5',margin:'auto',marginTop:'25px',
              backgroundColor: 'white',objectFit:'contain',objectPosition:'top center'}}
              imgStyle={{objectFit:'contain'}}
              />
            </div>

            </div>

            <div style={{width:'80%',marginLeft:'10%',marginRight:'10%',textAlign:'center'}}>
            <Link className="btn" to="/"> Home </Link>&nbsp;&nbsp;&nbsp;
            <Link to="/oferta/" className="btn">Oferta</Link>&nbsp;&nbsp;&nbsp;
            <Link className="btn" to="/blog/"> Blog </Link>&nbsp;&nbsp;&nbsp;
            <Link to="/o-nas/" className="btn"> O nas </Link> &nbsp;&nbsp;&nbsp;
            <Link to="/contact-us/" className="btn"> Contact us </Link> &nbsp;&nbsp;&nbsp;
            <Link to="/kariera/" className="btn"> Kariera </Link> &nbsp;&nbsp;&nbsp;
            <a className="btn" href="#newsletter"> Newsletter </a>&nbsp;&nbsp;&nbsp;
            <Link className="btn" to="/ebook/"> E-book </Link>&nbsp;&nbsp;&nbsp;

            </div>
            </div>


            <div
              className="full-width-image-container lazy-background"
              style={{
                backgroundImage: `url('/img/tlo5.svg')`,
                backgroundPosition: `bottom right`,
                backgroundSize:'cover',
                backgroundRepeat:'no-repeat',
                height:'200x',
                marginTop:'0px',
                marginBottom:'0px',
              }}
            ></div>*/}

      </Layout>
      </>
    )
  }

export default PortfolioPage


export const query = graphql`
  query FilesP{
    file(relativePath: {regex: "/optimized/kontakt.jpg/"}) {
        childImageSharp {
          fluid(fit: CONTAIN) {
          srcWebp
          srcSetWebp
          src
          srcSet
        }
        }
      }
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "portfolio-post" } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              industry
              templateKey
              date(formatString: "DD. MM. YYYY")
          }
        }
      }
    }
  }
`
